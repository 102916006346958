import { render, staticRenderFns } from "./Logos.vue?vue&type=template&id=7bd1f475&scoped=true&"
import script from "./Logos.vue?vue&type=script&lang=js&"
export * from "./Logos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bd1f475",
  null
  
)

export default component.exports