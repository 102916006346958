var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('material.suppliers.supplier'),"no-close-on-backdrop":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('b-tabs-header',{attrs:{"tabs":_vm.getTitles,"activeTab":_vm.activeTab},on:{"clickTab":_vm.handleClickTab,"update:activeTab":function($event){_vm.activeTab=$event},"update:active-tab":function($event){_vm.activeTab=$event},"close":function($event){_vm.modalActive = false}}})]},proxy:true},{key:"default",fn:function(){return [_c('div',[_c('validation-observer',{ref:"validation"},[(_vm.activeTab === 0)?_c('div',{attrs:{"title":_vm.$t('material.suppliers.supplier')}},[_c('b-form-group',{attrs:{"label":_vm.$t('general.tax_number')}},[_c('validation-provider',{attrs:{"rules":_vm.TAXValidationRules,"name":_vm.$t('general.tax_number')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',{staticClass:"tax_number_row"},[_c('div',[_c('b-form-input',{ref:"taxNumber",attrs:{"autofocus":""},on:{"input":_vm.checkTAXMatch},model:{value:(_vm.addObject.tax_number),callback:function ($$v) {_vm.$set(_vm.addObject, "tax_number", $$v)},expression:"addObject.tax_number"}})],1),_c('div',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.search(errors[0])}}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,739360674)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.name),callback:function ($$v) {_vm.$set(_vm.addObject, "name", $$v)},expression:"addObject.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1597630706)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.address')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.address),callback:function ($$v) {_vm.$set(_vm.addObject, "address", $$v)},expression:"addObject.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3709006403)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.country')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.country),callback:function ($$v) {_vm.$set(_vm.addObject, "country", $$v)},expression:"addObject.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3570639837)})],1)],1):_vm._e(),(_vm.activeTab === 1)?_c('div',{attrs:{"title":_vm.$t('form_data.contact_person')}},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.person_name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.person_name'),"rules":_vm.getContactNameRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"contactPersonName",attrs:{"autofocus":""},model:{value:(_vm.contactPerson.name),callback:function ($$v) {_vm.$set(_vm.contactPerson, "name", $$v)},expression:"contactPerson.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,118937711)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.email')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.email'),"rules":_vm.getContactMailRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contactPerson.email),callback:function ($$v) {_vm.$set(_vm.contactPerson, "email", $$v)},expression:"contactPerson.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1637627800)})],1),_c('b-form-group',{staticStyle:{"position":"relative"},attrs:{"label":_vm.$t('form_data.phone')}},[_c('span',{staticStyle:{"position":"absolute","right":"10px","margin-top":"10px"}},[_c('fa',{staticClass:"ml-1",attrs:{"id":"supported-phone-formats-tooltip","icon":"question-circle","color":"#72a5d8"}}),_c('b-tooltip',{attrs:{"target":"supported-phone-formats-tooltip","placement":"topright","triggers":"hover focus","custom-class":"expanded-tooltip"}},[_vm._v(" "+_vm._s(_vm.$t('general.supported_formats'))+":"),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$supported_phone_formats)}})])],1),_c('validation-provider',{attrs:{"name":_vm.$t('form_data.phone'),"rules":_vm.getContactPhoneRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contactPerson.phone),callback:function ($$v) {_vm.$set(_vm.contactPerson, "phone", $$v)},expression:"contactPerson.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3434664072)})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.addContactPerson}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)],1),(_vm.addObject.contacts && _vm.addObject.contacts.length > 0)?_c('div',{staticClass:"mt-1"},[_c('Table',{attrs:{"columnDefs":_vm.columnDefs,"rowData":_vm.addObject.contacts},on:{"deleteRow":_vm.removeContactPerson,"edit":_vm.editContactPerson}})],1):_vm._e()],1):_vm._e()])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.save')))])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }