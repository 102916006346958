var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.$t('general.ingredient'),"no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autofocus":""},model:{value:(_vm.addObject.name),callback:function ($$v) {_vm.$set(_vm.addObject, "name", $$v)},expression:"addObject.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.ingredients.amount_type')}},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.ingredients.amount_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.amount_types,"reduce":function (item) { return item.id; }},on:{"option:selected":_vm.changeDefault},model:{value:(_vm.addObject.amount_type),callback:function ($$v) {_vm.$set(_vm.addObject, "amount_type", $$v)},expression:"addObject.amount_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.packaging')}},[_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('validation-provider',{staticStyle:{"width":"100%"},attrs:{"name":_vm.$t('form_data.packaging'),"rules":_vm.getQuantityRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"value":_vm.packaging},on:{"input":_vm.changePackaging}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('form_data.quantity')+'-select',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticStyle:{"width":"150px","margin-left":"1rem"},attrs:{"disabled":!_vm.addObject.amount_type,"appendToBody":"","label":"name","options":_vm.types},model:{value:(_vm.addObject.packaging_unit),callback:function ($$v) {_vm.$set(_vm.addObject, "packaging_unit", $$v)},expression:"addObject.packaging_unit"}}),_c('small',{staticClass:"text-danger",staticStyle:{"width":"150px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-form-group',{attrs:{"label":_vm.$t('general.category')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.ingredient_categories,"reduce":function (item) { return item.id; }},model:{value:(_vm.addObject.ingredient_category_id),callback:function ($$v) {_vm.$set(_vm.addObject, "ingredient_category_id", $$v)},expression:"addObject.ingredient_category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(_vm._s(_vm.$t("keys.products.disabled")))]),_c('b-form-checkbox',{staticClass:"ml-2 red",attrs:{"switch":""},model:{value:(_vm.addObject.disabled),callback:function ($$v) {_vm.$set(_vm.addObject, "disabled", $$v)},expression:"addObject.disabled"}})],1)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}}),_c('UsedAtModal',{ref:"similarIngredientsModal",attrs:{"title":_vm.$t('used_at_modal.do_you_want_to_add_ingredient')},on:{"confirm":_vm.confirm,"cancel":_vm.close}},[(_vm.similarIngredients.length > 0)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('used_at_modal.found_similar_ingredients'))+": ")]),_c('ul',_vm._l((_vm.similarIngredients),function(item,id){return _c('li',{key:'similarIngredientsAlreadyExists_' + id,staticStyle:{"font-weight":"bold"}},[_c('div',[_vm._v(" "+_vm._s(item.item.name)+" ")])])}),0)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }