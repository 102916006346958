<template>
    <div>
        <b-modal :title="$t('general.ingredient')" v-model="modalActive" no-close-on-backdrop>
            <template #default>
                <validation-observer ref="validation">
                    <b-form-group :label="$t('form_data.name')">
                        <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                            <b-form-input autofocus v-model="addObject.name"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('keys.ingredients.amount_type')">
                        <validation-provider #default="{ errors }" :name="$t('keys.ingredients.amount_type')" rules="required">
                            <v-select v-model="addObject.amount_type" appendToBody label="name" :options="amount_types"
                                      :reduce="item => item.id" @option:selected="changeDefault"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('form_data.packaging')">
                        <div style="display: flex; width: 100%">
                            <validation-provider style="width: 100%" #default="{ errors }" :name="$t('form_data.packaging')"
                                                 :rules="getQuantityRules">
                                <b-form-input :value="packaging" v-on:input="changePackaging"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider #default="{ errors }" :name="$t('form_data.quantity')+'-select'"
                                                 rules="required">
                                <v-select style="width: 150px; margin-left: 1rem" v-model="addObject.packaging_unit"
                                          :disabled="!addObject.amount_type" appendToBody label="name" :options="types"/>
                                <small style="width: 150px" class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>
                    </b-form-group>
                    <b-form-group :label="$t('general.category')">
                        <validation-provider #default="{ errors }" :name="$t('general.category')" rules="required">
                            <v-select v-model="addObject.ingredient_category_id" appendToBody label="name"
                                      :options="ingredient_categories" :reduce="item => item.id"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <div class="d-flex">
                        <div>{{$t("keys.products.disabled")}}</div>
                        <b-form-checkbox
                            switch
                            class="ml-2 red"
                            v-model="addObject.disabled"
                        />
                    </div>
                </validation-observer>
            </template>

            <template #modal-footer>
                <b-button variant="primary" @click="validate">
                    <span v-if="editing">
                        <feather-icon icon="EditIcon" class="mr-50"/>
                        <span class="align-middle">{{ $t('general.edit') }}</span>
                    </span>
                    <span v-else>
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">{{ $t('general.add') }}</span>
                    </span>
                </b-button>
            </template>


        </b-modal>

        <UsedAtModal ref="similarIngredientsModal" @confirm="confirm" @cancel="close" :title="$t('used_at_modal.do_you_want_to_add_ingredient')">
            <div v-if="similarIngredients.length > 0">

                <div>
                    {{ $t('used_at_modal.found_similar_ingredients') }}:
                </div>
                <ul>
                    <li style="font-weight: bold;" v-for="(item, id) of similarIngredients" :key="'similarIngredientsAlreadyExists_' + id">
                        <div>
                            {{item.item.name}}
                        </div>
                    </li>
                </ul>
            </div>
        </UsedAtModal>

    </div>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormCheckbox} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {required, minValue, decimalCustom} from '@validations'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'
    import Fuse from 'fuse.js'
    import UsedAtModal from '@/views/components/UsedAtModal.vue'
    import {getMoneyTypes, getWeightTypes, getVolumeTypes, getPieceTypes, getAmountTypes} from '@/libs/constants/ingredients.js'

    export default {
        components: {
            UsedAtModal,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BFormCheckbox,
            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        props: {
            ingredients: {
                Type: Array
            },
            ingredient_categories: {
                Type: Array
            }
        },
        data() {
            return {
                clone: false,
                required,
                minValue,
                decimalCustom,
                modalActive: false,
                addObject: {
                    name: '',
                    amount_type: '',
                    packaging: '',
                    packaging_unit: '',
                    ingredient_category_id: '',
                    disabled: false
                },
                packaging: 0,
                editing: false,
                types: [],
                similarIngredients : []
            }
        },
        computed: {
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            },
            amount_types() {
                return getAmountTypes()
            },
            getQuantityRules() {
                if (this.addObject.packaging_unit !== null) {
                    if (this.addObject.packaging_unit.name === 'g' || this.addObject.packaging_unit.name === 'mL') {
                        return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                    } else if (this.addObject.packaging_unit.name === 'dag') {
                        return 'required|decimalCustom:1|mustBePositive:true|min_number:0'
                    } else if (this.addObject.packaging_unit.name === 'dL' || this.addObject.packaging_unit.name === 'Kos') {
                        return 'required|decimalCustom:2|mustBePositive:true|min_number:0'
                    } else if (this.addObject.packaging_unit.name === 'kg' || this.addObject.packaging_unit.name === 'L') {
                        return 'required|decimalCustom:3|mustBePositive:true|min_number:0'
                    } else if (this.addObject.packaging_unit.name === 'EUR') {
                        return 'required|decimalCustom:6|mustBePositive:true|min_number:0'
                    }

                    return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                } else {
                    return ''
                }
            }
        },
        methods: {
            changeDefault() {
                if (this.addObject.amount_type === 'piece') {
                    this.addObject.packaging_unit = this.piece_types.find(ele => ele.factor === 100)
                    this.types = this.piece_types
                } else if (this.addObject.amount_type === 'volume') {
                    this.addObject.packaging_unit = this.volume_types.find(ele => ele.factor === 1000)
                    this.types = this.volume_types
                } else if (this.addObject.amount_type === 'weight') {
                    this.addObject.packaging_unit = this.weight_types.find(ele => ele.factor === 1)
                    this.types = this.weight_types
                } else if (this.addObject.amount_type === 'money') {
                    this.addObject.packaging_unit = this.money_types.find(ele => ele.factor === 1000000)
                    this.types = this.money_types
                }
            },
            open(data = null, clone = null) {
                this.clone = clone
                if (data !== null) {
                    const tmp = JSON.parse(JSON.stringify(data))
                    if (tmp.amount_type === 'piece') {

                        this.types = this.piece_types

                        const tmp_type = this.piece_types.find(ele => ele.name === tmp.packaging_unit)
                        if (tmp_type) {
                            tmp.packaging = tmp.packaging / tmp_type.factor
                            tmp.packaging_unit = tmp_type
                        }

                    } else if (tmp.amount_type === 'volume') {

                        this.types = this.volume_types
                        const tmp_type = this.volume_types.find(ele => ele.name === tmp.packaging_unit)

                        if (tmp_type) {
                            tmp.packaging = tmp.packaging / tmp_type.factor
                            tmp.packaging_unit = tmp_type
                        }
                    } else if (tmp.amount_type === 'weight') {
                        this.types = this.weight_types
                        const tmp_type = this.weight_types.find(ele => ele.name === tmp.packaging_unit)

                        if (tmp_type) {
                            tmp.packaging = tmp.packaging / tmp_type.factor
                            tmp.packaging_unit = tmp_type
                        }
                    } else if (tmp.amount_type === 'money') {
                        this.types = this.money_types
                        const tmp_type = this.money_types.find(ele => ele.name === tmp.packaging_unit)

                        if (tmp_type) {
                            tmp.packaging = tmp.packaging / tmp_type.factor
                            tmp.packaging_unit = tmp_type
                        }
                    }
                    this.packaging = tmp.packaging
                    if (clone) {
                        tmp.name = ''
                        tmp.disabled = false
                        tmp.ingredients = []
                    }
                    this.addObject = tmp
                    this.editing = !clone

                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        ingredient_category_id: '',
                        amount_type: '',
                        packaging: '',
                        packaging_unit: '',
                        disabled: false
                    }
                    this.packaging = null
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) => {
                    if (success) {
                        if (this.checkIfSimilarExists()) {
                            this.$refs.similarIngredientsModal.show(false)
                            return
                        }

                        this.confirm()
                    }
                })
            },
            confirm() {
                if (this.editing) this.edit()
                else this.add()
            },
            getItemAmountFromName(item) {
                let result = ''
                const regex = /\d+((.|,)\d+)?/

                if (regex.test(item)) {
                    const itemArray = regex.exec(item)
                    if (itemArray.length > 0) {
                        result = itemArray[0]
                    }
                }
                return result
            },
            checkIfSimilarExists() {
                const ingredient = this.addObject.name.trim()

                const fuse = new Fuse(this.ingredients, {
                    keys: ['name'],
                    threshold: 0.22,
                    distance: 0
                })

                const result = fuse.search(ingredient)

                if (result.length > 0) {
                    this.similarIngredients = result
                    const ingredientAmount = this.getItemAmountFromName(ingredient)

                    if (ingredientAmount !== '') {

                        for (const item of result) {

                            const itemAmount = this.getItemAmountFromName(item.item.name)

                            if (itemAmount !== '') {
                                if (itemAmount === ingredientAmount) {
                                    return true

                                }
                            }

                        }
                    } else {
                        return true
                    }
                }
                return false
            },
            close() {
                this.modalActive = false

                this.addObject = {
                    name: '',
                    amount_type: '',
                    packaging: '',
                    packaging_unit: '',
                    ingredient_category_id: '',
                    disabled: false
                }
            },
            async add() {
                try {
                    const payload = {
                        name: this.addObject.name,
                        amount_type: this.addObject.amount_type,
                        packaging: parseInt(Number(this.addObject.packaging) * this.addObject.packaging_unit.factor),
                        packaging_unit: this.addObject.packaging_unit.name,
                        disabled: this.addObject.disabled
                    }

                    const response = await this.$http.post(`/api/client/v1/ingredients/categories/${this.addObject.ingredient_category_id}`, payload)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        amount_type: '',
                        packaging: '',
                        packaging_unit: '',
                        ingredient_category_id: '',
                        disabled: false
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async edit() {
                try {

                    const payload = {
                        name: this.addObject.name,
                        amount_type: this.addObject.amount_type,
                        packaging: parseInt(Number(this.addObject.packaging) * this.addObject.packaging_unit.factor),
                        packaging_unit: this.addObject.packaging_unit.name,
                        ingredient_category_id: this.addObject.ingredient_category_id,
                        disabled: this.addObject.disabled
                    }

                    await this.$http.post(`/api/client/v1/ingredients/${this.addObject.id}`, payload)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        amount_type: '',
                        packaging: '',
                        packaging_unit: '',
                        ingredient_category_id: '',
                        disabled: false
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            changePackaging(val) {
                this.packaging = JSON.parse(JSON.stringify(val))

                if (val) {
                    val = String(val).replace(',', '.')
                }

                this.addObject.packaging = Number(val)
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
 li{
   margin: 0;
 }

</style>