<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.products')">
            <div class="d-flex justify-content-end">
                <b-form-checkbox class="mr-2 my-auto h-full pt-0.5 disabled-items" switch v-model="showDisabled">{{ $t('keys.show_disabled_products')}}</b-form-checkbox>
                <b-button variant="primary" @click="$refs.addModal.open()" v-if="$hasPermission($permissions.ProductsWrite)">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.products.add_product') }}
                </b-button>
            </div>

            <Table ref="productsTable" v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="products" @clone="(params) =>editRow(params, true)" @enable="enable" @disable="disable" @editRow="(params) => editRow(params, false)" @edit="edit" />
        </b-card>

        <AddProduct ref="addModal" :vats="vats" :ingredients="ingredients" :productSubcategories="productSubcategories" :productOrderOptions="productOrderOptions" v-on:itemAdded="loadData"/>
        <UsedAtModal ref="usedAtModal" @confirm="confirmUsedAt" @cancel="cancelUsedAt">
            <p v-if="productName">
                {{$t('keys.products.product')}} <b>{{productName}}</b> {{$t('keys.products.text_disable_product')}}
            </p>
            <p>
                {{ $t('general.wish_continue') }}
            </p>
        </UsedAtModal>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddProduct from '@/views/Product/AddProduct'
    import {BButton, BCard, BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'
    import UsedAtModal from '@/views/components/UsedAtModal.vue'
    import {Tiers} from '@/libs/enums/Tiers'

    export default {
        mixins: [itemChange],
        components: {Table, AddProduct, BCard, BOverlay, BButton, BFormCheckbox, UsedAtModal},
        data() {
            return {
                productsRaw: [],
                vats: [],
                productSubcategories: [],
                ingredients:[],
                showLoader: false,
                arraysLoad: false,
                business_units: [],
                selectedUnit: null,
                data: null,
                showDisabled: false,
                selectedId: null,
                selectedObj: null,
                productName: null,
                productOrderOptions: []
            }
        },
        methods: {
            getMaxSalePrice(data) {
                return Math.max(...data.sale_price.map(el => el.sale_price))
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/products/products_with_prices')
                    const response2 = this.$http.get('/api/client/v1/vat_groups/')
                    const response3 = this.$http.get('/api/client/v1/product_subcategories/')
                    const response4 = this.$http.get('/api/client/v1/product_order_options/')

                    const responses = await Promise.all([response1, response2, response3, response4])
                    this.productsRaw = responses[0].data ?? []
                    this.vats = responses[1].data ?? []
                    this.productSubcategories = responses[2].data ?? []
                    this.productOrderOptions = responses[3].data ?? []

                    if (this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) {
                        const res = await this.$http.get('/api/client/v1/ingredients/')
                        this.ingredients = res.data ?? []
                    }


                    await this.itemChange()
                    if (this.productsRaw && this.productsRaw.length > 0) {
                        for (let i = 0; i < this.productsRaw.length; i++) {
                            this.productsRaw[i].contains_ingredients = this.countIngredients(this.productsRaw[i])
                        }
                    }

                    if (this.$refs.productsTable) {
                        this.$refs.productsTable.refresh()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))

                } finally {
                    this.showLoader = false
                }
            },
            async confirmUsedAt() {
                this.showLoader = true
                try {
                    await this.$http.patch(`/api/client/v1/products/${this.selectedId}`, this.selectedObj)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    await this.loadData()
                    this.selectedObj = null
                    this.selectedId = null
                    this.showLoader = false
                }
            },
            cancelUsedAt() {
                this.selectedObj = null
                this.selectedId = null
            },
            enable(params) {
                const main = {
                    data: params,
                    column: {
                        colId: 'disabled'
                    },
                    newValue: false
                }

                this.edit(main)
            },
            disable(params) {
                const main = {
                    data: params,
                    column: {
                        colId: 'disabled'
                    },
                    newValue: true
                }

                this.edit(main)
            },
            async edit(params) {
                try {

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}

                    if (editObject.object_data.disabled) {
                        this.productName = params.data.name
                        this.selectedObj = editObject
                        this.selectedId = params.data.id

                        this.$refs.usedAtModal.show()
                    } else {
                        await this.$http.patch(`/api/client/v1/products/${params.data.id}`, editObject)
                        this.$printSuccess(this.$t('print.success.edit'))

                        await this.loadData()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            editRow(data, clone = null) {
                this.$refs.addModal.open(data, clone)
            },
            getCategoryName(value) {
                if (value) {
                    const item = this.productSubcategories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            countIngredients(data) {
                return !!(data && data.ingredients && data.ingredients.length > 0)
            }

        },
        computed: {
            columnDefs() {
                const hasPermissions = this.$hasPermission(this.$permissions.ProductsWrite)

                const defs = [
                    { headerName: this.$t('table_fields.product_code'), field: 'product_code', filter: true, editable: false},
                    { headerName: this.$t('table_fields.name'), field: 'name', editable: hasPermissions,  filter: true},
                    {
                        headerName: this.$t('table_fields.sale_price'),
                        field: 'sale_price',
                        filter: false,
                        editable: false,
                        sortable: true,
                        valueGetter: (params) => {
                            return this.getMaxSalePrice(params.data)
                        },
                        cellRenderer: 'CustomTooltip'
                    },
                    { headerName: this.$t('table_fields.color'), field: 'color', filter: false, editable: hasPermissions, cellRenderer: 'DisplayColor', cellEditorPopup: true, cellEditorPopupPosition: 'under',  cellEditorFramework: 'colorSelectorCell'},
                    { headerName: this.$t('table_fields.subcategory'), field: 'product_category_id', editable: hasPermissions, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.productSubcategories} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.productSubcategories} }, cellRenderer: (params) => this.getCategoryName(params.value)}
                ]

                if (this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) {
                    defs.push(
                        { headerName: this.$t('table_fields.contains_ingredients'), field: 'contains_ingredients', filter: true, floatingFilterComponentParams: {type: 'select', values: [{name: this.$t('general.yes'), value: true}, {name: this.$t('general.no'), value: false}], selectValue: 'value'}, editable: false, cellRenderer: 'DisplayActive'}
                    )
                }

                if (this.$hasPermission(this.$permissions.ProductsWrite)) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 220, cellRendererParams: {button: 'Clone'}}
                    )
                }

                return defs
            },

            products() {
                const prods = this.productsRaw

                if (this.showDisabled) {
                    return prods
                }

                return prods.filter((x) => !x.disabled)
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>