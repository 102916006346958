<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.furs_certificates')">
            <div class="d-flex justify-content-end mb-2" v-if="$hasPermission($permissions.ConfigurationWrite)">
                <b-button variant="primary" @click="$refs.addFURSCertificateModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('config.furs_certificates.add_certificate') }}
                </b-button>
            </div>

            <BasicTable
                :data="tableData"
                :columns="columns"
                v-slot="props">

                <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                    <b-button variant="danger" class="ml-1" @click="removeCertificate(props.row.id)">
                        <feather-icon
                            icon="Trash2Icon"
                        />
                    </b-button>
                </div>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>

            </BasicTable>

        </b-card>

        <AddFURSCertificateModal ref="addFURSCertificateModal" @itemAdded="loadData" />
    </b-overlay>
</template>
<script>

    import {BCard, BButton, BOverlay} from 'bootstrap-vue'
    import AddFURSCertificateModal from '@/views/FURSCertificate/AddFURSCertificateModal'
    import BasicTable from '@/views/components/BasicTable.vue'
    import dayjs from 'dayjs'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'


    export default {
        mixins: [itemChange],
        components: {
            AddFURSCertificateModal,
            BButton,
            BCard,
            BOverlay,
            BasicTable
        },
        computed: {
            tableData() {
                return this.rawData.map((item) => {
                    return {
                        id: item.id,
                        valid_until: dayjs(item.valid_until).format('DD/MM/YYYY HH:mm'),
                        added_at: dayjs(item.added_at).format('DD/MM/YYYY HH:mm'),
                        serial_number: item.serial_number
                    }
                })
            },
            columns() {
                const hasPermission =  this.$hasPermission(this.$permissions.ConfigurationWrite)
                const defs = [
                    {
                        label: this.$t('config.furs_certificates.serial_number'),
                        displayType: 0,
                        field: 'serial_number'
                    },
                    {
                        label: this.$t('config.added'),
                        displayType: 0,
                        field: 'added_at'
                    },
                    {
                        label: this.$t('keys.valid_to'),
                        displayType: 0,
                        field: 'valid_until'
                    }

                ]

                if (hasPermission) {
                    defs.push(
                        {
                            label: this.$t('config.furs_certificates.actions'),
                            displayType: 1,
                            field: 'actions',
                            filterOptions: {
                                enabled: false
                            }
                        }
                    )
                }

                return defs
            }
        },
        data() {
            return {
                rawData: [],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/furs_certificate/')
                    this.rawData = response.data ?? []
                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async removeCertificate(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/furs_certificate/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
