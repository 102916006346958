<template>
    <b-modal :title="$t('keys.product_display_groups.product_display_group')" v-model="modalActive" size="lg" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('form_data.name')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                        <b-form-input autofocus v-model="addObject.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-row>
                    <b-col>
                        <validation-provider #default="{ errors }" :name="$t('keys.color')">
                            <colorSelector v-model="addObject.color" :product-name="addObject.name"></colorSelector>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>

                <div class="d-flex justify-content-end mb-1">
                    <b-form-checkbox class="mr-2 my-auto h-full pt-0.5 disabled-items" switch v-model="showDisabled" @change="recomputeProducts">{{ $t('keys.show_disabled_products')}}</b-form-checkbox>
                </div>
                <TableWithCheckBox ref="tableDisplayGroup" v-if="arraysLoad" :columnDefs="columnDefs" :rowData="products" :selected="addObject.product_ids" selectValue="id" :findValueInArray="true" @selectionChanged="selectionChanged"/>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BRow, BCol, BFormCheckbox} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import TableWithCheckBox from '@/views/components/Table/TableWithCheckBox.vue'
    import colorSelector from '@/views/ColorSelector/ColorSelector.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BFormCheckbox,
            TableWithCheckBox,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BRow,
            BCol,
            ValidationProvider,
            ValidationObserver,
            colorSelector
        },
        props: {
            productsRaw: {
                type: Array
            },
            selectedBusinessUnitId: {
                tyep: String
            }
        },
        data() {
            return {
                arraysLoad: false,
                modalActive: false,
                addObject: {
                    name: '',
                    color: '#3E3E3E',
                    text_color: '#E1E1E1',
                    product_ids: [],
                    business_unit_id: '',
                    productSubcategories: []
                },
                required,
                editing: false,
                showDisabled: false,
                products: [],
                originalSelection: []
            }
        },
        computed: {
            columnDefs() {
                return [
                    { headerName: this.$t('table_fields.products'), field: 'name', editable: false, filter: true },
                    { headerName: this.$t('table_fields.subcategory'), field: 'product_category_id', editable: true, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.productSubcategories} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.productSubcategories} }, cellRenderer: (params) => this.getCategoryName(params.value)},
                    { headerName: this.$t('table_fields.used'), field: 'included', editable: false, filter: true, floatingFilterComponentParams: {type: 'select', values: [{name: this.$t('general.yes'), value: true}, {name: this.$t('general.no'), value: false}], selectValue: 'value'}, cellRenderer: 'DisplayActive' }
                ]
            }
        },
        methods: {
            async loadData() {
                try {
                    const res = await this.$http.get('/api/client/v1/product_subcategories/')
                    this.productSubcategories = res.data ?? []
                    this.arraysLoad = true
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            },
            async recomputeProducts() {
                const prods = this.productsRaw.map((product) => {
                    return {
                        ...product,
                        enabled: !product.disabled
                    }
                })

                if (this.showDisabled) {
                    this.products = prods
                } else {
                    this.products = prods.filter((x) => !x.disabled)
                }

                if (this.$refs.tableDisplayGroup) {
                    const fm = this.$refs.tableDisplayGroup.getFilterModel()
                    await this.$refs.tableDisplayGroup.refresh()
                    await this.$refs.tableDisplayGroup.updateSelected(this.addObject.product_ids)
                    this.$refs.tableDisplayGroup.setFilterModel(fm)
                }
            },
            getCategoryName(value) {
                if (value) {
                    const item = this.productSubcategories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = data
                    this.originalSelection = this.addObject.product_ids
                    this.editing = true
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        color: '#3E3E3E',
                        text_color: '#E1E1E1',
                        product_ids: [],
                        business_unit_id: ''
                    }
                }
                this.showDisabled = false
                this.recomputeProducts()
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    this.addObject.business_unit_id = this.selectedBusinessUnitId
                    const response = await this.$http.post('/api/client/v1/product_display_groups/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        color: '#3E3E3E',
                        text_color: '#E1E1E1',
                        product_ids: [],
                        business_unit_id: '',
                        order_index: null
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async edit() {
                try {
                    await this.$http.put(`/api/client/v1/product_display_groups/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        color: '#3E3E3E',
                        text_color: '#E1E1E1',
                        product_ids: [],
                        business_unit_id: ''
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            selectionChanged(val) {
                this.addObject.product_ids = val
            }
        },
        mounted() {
            this.loadData()
            this.products = this.productsRaw
        }
    }
</script>
