var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","title":_vm.$t('keys.payment_types.payment_type'),"no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('b-tabs-header',{attrs:{"tabs":_vm.titles,"activeTab":_vm.activeTab},on:{"clickTab":_vm.handleClickTab,"update:activeTab":function($event){_vm.activeTab=$event},"update:active-tab":function($event){_vm.activeTab=$event},"close":function($event){_vm.modalActive = false}}})]},proxy:true},{key:"default",fn:function(){return [_c('div',[_c('validation-observer',{ref:"validation"},[(_vm.activeTab === 0)?_c('div',{attrs:{"title":_vm.$t('keys.payment_types.payment_type_config')}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.addObject.active),callback:function ($$v) {_vm.$set(_vm.addObject, "active", $$v)},expression:"addObject.active"}},[_vm._v(_vm._s(_vm.$t('general.active')))])],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"name",attrs:{"autofocus":""},model:{value:(_vm.addObject.name),callback:function ($$v) {_vm.$set(_vm.addObject, "name", $$v)},expression:"addObject.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,904832315)})],1),_c('b-form-group',{attrs:{"disabled":_vm.paymentTypeAllowed}},[_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":_vm.taxCertificationChanged},model:{value:(_vm.addObject.tax_certification),callback:function ($$v) {_vm.$set(_vm.addObject, "tax_certification", $$v)},expression:"addObject.tax_certification"}},[_vm._v(_vm._s(_vm.$t('keys.payment_types.tax_certification')))])],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.payment_types.payment_type')}},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.payment_types.payment_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.payment_types,"reduce":function (item) { return item.value; }},on:{"input":_vm.paymentTypeAllowedChange},model:{value:(_vm.addObject.payment_type),callback:function ($$v) {_vm.$set(_vm.addObject, "payment_type", $$v)},expression:"addObject.payment_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3027746109)})],1),(!_vm.addObject.tax_certification)?_c('div',[_c('b-form-group',{staticClass:"mt-2",attrs:{"label":_vm.$t('keys.payment_types.custom_numbering')}},[_c('validation-provider',{attrs:{"disabled":_vm.addObject.tax_certification,"name":_vm.$t('keys.payment_types.custom_numbering'),"rules":"required|min:1|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.custom_numbering_key),callback:function ($$v) {_vm.$set(_vm.addObject, "custom_numbering_key", $$v)},expression:"addObject.custom_numbering_key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1463725362)})],1)],1):_vm._e(),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":_vm.$t('keys.payment_types.fixed_price')}},[_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":_vm.emptyField},model:{value:(_vm.addObject.fixed_price_enabled),callback:function ($$v) {_vm.$set(_vm.addObject, "fixed_price_enabled", $$v)},expression:"addObject.fixed_price_enabled"}})],1),(_vm.addObject.fixed_price_enabled)?_c('b-form-group',[_c('validation-provider',{ref:"fixed_price",attrs:{"disabled":!_vm.addObject.fixed_price_enabled,"name":_vm.$t('keys.payment_types.fixed_price'),"rules":("required: " + (_vm.addObject.fixed_price_enabled) + "|min_value:0|decimalCustom:2|mustBePositive:true")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.addObject.fixed_price_enabled},model:{value:(_vm.addObject.fixed_price),callback:function ($$v) {_vm.$set(_vm.addObject, "fixed_price", _vm._n($$v))},expression:"addObject.fixed_price"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3359275538)})],1):_vm._e(),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('keys.color')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('colorSelector',{attrs:{"product-name":_vm.addObject.name},model:{value:(_vm.addObject.color),callback:function ($$v) {_vm.$set(_vm.addObject, "color", $$v)},expression:"addObject.color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1341031732)})],1)],1):_vm._e(),(_vm.activeTab === 1)?_c('div',{attrs:{"title":_vm.$t('keys.advanced')}},[_c('div',{staticClass:"pb-1"},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.payment_types.print_qr_code_native'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.addObject.open_cash_drawer),callback:function ($$v) {_vm.$set(_vm.addObject, "open_cash_drawer", $$v)},expression:"addObject.open_cash_drawer"}},[_vm._v(" "+_vm._s(_vm.$t('general.open_cash_drawer'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3871426670)}),(_vm.addObject.payment_type === 0)?_c('div',{staticClass:"mt-2"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.addObject.pos_integration),callback:function ($$v) {_vm.$set(_vm.addObject, "pos_integration", $$v)},expression:"addObject.pos_integration"}},[_vm._v(_vm._s(_vm.$t('keys.payment_types.pos_integration')))])],1):_vm._e(),(_vm.addObject.payment_type === 0)?_c('div',{staticClass:"mt-2"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.addObject.my_cards_integration),callback:function ($$v) {_vm.$set(_vm.addObject, "my_cards_integration", $$v)},expression:"addObject.my_cards_integration"}},[_vm._v(_vm._s(_vm.$t('keys.payment_types.my_cards_integration')))])],1):_vm._e(),(_vm.addObject.my_cards_integration)?_c('div',{staticClass:"mt-2"},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.payment_types.my_cards_url'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('keys.payment_types.my_cards_url')}},[_c('b-form-input',{model:{value:(_vm.addObject.my_cards_config.url),callback:function ($$v) {_vm.$set(_vm.addObject.my_cards_config, "url", $$v)},expression:"addObject.my_cards_config.url"}},[_vm._v(_vm._s(_vm.$t('keys.payment_types.my_cards_url')))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,242174861)}),_c('validation-provider',{attrs:{"name":_vm.$t('keys.payment_types.my_cards_username'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('keys.payment_types.my_cards_username')}},[_c('b-form-input',{model:{value:(_vm.addObject.my_cards_config.username),callback:function ($$v) {_vm.$set(_vm.addObject.my_cards_config, "username", $$v)},expression:"addObject.my_cards_config.username"}},[_vm._v(_vm._s(_vm.$t('keys.payment_types.my_cards_username')))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,959288176)}),_c('validation-provider',{attrs:{"name":_vm.$t('keys.payment_types.my_cards_password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('keys.payment_types.my_cards_password')}},[_c('b-form-input',{model:{value:(_vm.addObject.my_cards_config.password),callback:function ($$v) {_vm.$set(_vm.addObject.my_cards_config, "password", $$v)},expression:"addObject.my_cards_config.password"}},[_vm._v(_vm._s(_vm.$t('keys.payment_types.my_cards_password')))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3020145273)})],1):_vm._e()],1)]):_vm._e()])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }