<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.subcategories')">
            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.ProductsWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.subcategories.add_subcategory') }}
                </b-button>
            </div>

            <Table v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="ingredientSubcategories" @deleteRow="remove" @editRow="editRow" @edit="edit" v-on:itemAdded="loadData"/>
        </b-card>

        <AddIngredientSubcategory ref="addModal" :ingredientCategories="ingredientCategories" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddIngredientSubcategory from '@/views/Ingredient/subcategory/AddIngredientSubcategory'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        components: {Table, AddIngredientSubcategory, BCard, BOverlay, BButton},
        computed: {

            columnDefs() {
                const hasPermissions = this.$hasPermission(this.$permissions.ProductsWrite)

                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: hasPermissions, field: 'name', filter: true},
                    { headerName: this.$t('table_fields.category'), editable: hasPermissions, field: 'main_category_id', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.ingredientCategories, multiple: true} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.ingredientCategories} }, cellRenderer: (params) => this.getCategoryName(params.value)}
                ]

                if (hasPermissions) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                vats: [],
                ingredientCategories: [],
                ingredientSubcategories: [],
                showLoader: false,
                arraysLoad: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/ingredient_subcategories/')
                    const response2 = this.$http.get('/api/client/v1/ingredient_categories/')

                    const responses = await Promise.all([response1, response2])
                    this.ingredientSubcategories = responses[0].data ?? []
                    this.ingredientCategories = responses[1].data ?? []
                    await this.itemChange()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true
                    const editObject = params.data
                    await this.$http.patch(`/api/client/v1/ingredient_subcategories/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/ingredient_subcategories/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getCategoryName(value) {
                if (value) {
                    const item = this.ingredientCategories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
