<template>
    <b-overlay :show="showLoader">
        <b-card class="d-flex align-center" :title="$t('menu.subcategory.logos')">

            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.ConfigurationWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('config.logos.add_logo') }}
                </b-button>
            </div>

            <Table ref="test" class="mt-1" :columnDefs="columnDefs" :rowData="logos" @deleteRow="remove" @editRow="previewLogo"/>
        </b-card>

        <addLogo ref="addModal"  @logoAdded="loadData" v-on:itemAdded="loadData()"/>
        <image-gallery ref="imageGallery" filename="config.logos.logo" :attachments="logos"></image-gallery>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import {BButton, BCard, BOverlay } from 'bootstrap-vue'
    import addLogo from '@/views/Logo/AddLogo.vue'
    import ImageGallery from '@/views/components/Gallery/ImageGallery.vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        components: {ImageGallery, Table, addLogo, BCard, BOverlay, BButton},
        computed: {
            statuses() {
                return [
                    {id: 1, name: this.$t('config.logos.status.converting'), variant: 'secondary'},
                    {id: 2, name: this.$t('config.logos.status.available'), variant: 'success'}
                ]
            },
            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.ConfigurationWrite)
                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: false,  field: 'name', filter: true},
                    { headerName: this.$t('table_fields.status'),
                      editable: false,
                      field: 'status',
                      filter: false,
                      floatingFilterComponentParams:   {type: 'select', values: this.statuses, multiple: false, selectValue: 'id'},
                      cellRenderer: 'DisplayStatus',
                      cellRendererParams: {values: this.statuses},
                      cellEditorFramework: 'selectEditor'}
                ]

                if (hasPermission) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: () => { return {button: 'Logo' } }}
                    )
                } else {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: () => { return {button: 'LogoNoPermission' } }}
                    )
                }
                return defs
            }
        },
        data() {
            return {
                logos: [],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/logos/')
                    const tmp = response.data ?? []
                    this.logos = []
                    await this.itemChange()

                    for (const tmpElement of tmp) {
                        this.logos.push({
                            id: tmpElement.id,
                            url:`/api/client/v1/logos/${  tmpElement.id  }/2500`,
                            status: tmpElement.status,
                            name: tmpElement.display_name
                        })
                    }
                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            previewLogo(data) {
                const index = this.logos.findIndex(ele => ele.id === data.id)
                if (index > 0) {
                    this.$refs.imageGallery.open(index)
                } else {
                    this.$refs.imageGallery.open(0)
                }
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/logos/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (error) {
                    if (error.response.status === 302) {
                        this.$printError(this.$t('print.error.logo_used'))
                    } else {
                        this.$printError(this.$t('print.error.on_delete'))
                    }
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
