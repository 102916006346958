import { render, staticRenderFns } from "./Ingredients.vue?vue&type=template&id=5147f509&scoped=true&"
import script from "./Ingredients.vue?vue&type=script&lang=js&"
export * from "./Ingredients.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5147f509",
  null
  
)

export default component.exports