<template>
    <b-modal :title="$t('config.furs_certificates.add_certificate')" v-model="modalActive" @hidden="certificatePassword = null" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('general.password')">
                    <validation-provider #default="{ errors }" :name="$t('general.password')" rules="required">
                        <b-form-input autofocus v-model="certificatePassword"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('config.furs_certificates.certificate')">
                    <validation-provider #default="{ errors }" :name="$t('config.furs_certificates.certificate')" rules="required">
                        <b-form-file v-model="certificateFile"
                                     :placeholder="`${$t('config.choose_file')}...`"
                                     :drop-placeholder="`${$t('config.drop_file')}...`"
                                     :browse-text="`${$t('config.browse')}...`"
                                     accept=".p12"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="addCertificate">
                <span>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import { BModal, BFormGroup, BFormInput, BFormFile, BButton } from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BModal,
            BFormGroup,
            BFormInput,
            BFormFile,
            BButton
        },
        data() {
            return {
                modalActive: false,
                certificateFile: null,
                certificatePassword: null,
                required
            }
        },
        methods: {
            open() {
                this.modalActive = true
            },
            async addCertificate() {
                try {

                    const success = await this.$refs.validation.validate()
                    if (!success) {
                        return
                    }

                    const header = {headers: {'Content-Type': 'multipart/form-data'}}
                    const formData = new FormData()
                    formData.append('certificate', this.certificateFile)
                    formData.append('password', this.certificatePassword)

                    await this.$http.post('/api/client/v1/furs_certificate/', formData, header)

                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.add'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            }
        }
    }
</script>