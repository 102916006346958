import { render, staticRenderFns } from "./ImageGallery.vue?vue&type=template&id=f8e1a472&scoped=true&"
import script from "./ImageGallery.vue?vue&type=script&lang=js&"
export * from "./ImageGallery.vue?vue&type=script&lang=js&"
import style0 from "./ImageGallery.vue?vue&type=style&index=0&id=f8e1a472&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8e1a472",
  null
  
)

export default component.exports