<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.payment_types')">

            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.GeneralWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.payment_types.add_payment_type') }}
                </b-button>
            </div>

            <Table class="mt-1" :columnDefs="columnDefs" :rowData="paymentTypes" @deleteRow="remove" @editRow="editRow" @edit="edit"/>
        </b-card>

        <AddPaymentType ref="addModal" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddPaymentType from '@/views/PaymentType/AddPaymentType'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'
    import {Tiers} from '@/libs/enums/Tiers'
    import {PaymentTypes} from '@/libs/enums/PaymentTypes'

    export default {
        mixins: [itemChange],
        components: {Table, AddPaymentType, BCard, BOverlay, BButton},
        computed: {
            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.GeneralWrite)
                const hasTierPermission = this.$hasTierPermission(Tiers.Premium, Tiers.Standard)
                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: params => hasPermission && (hasTierPermission || params.data.payment_type !== PaymentTypes.RoomTransfer), field: 'name', filter: true},
                    { headerName: this.$t('table_fields.color'), editable: params => hasPermission && (hasTierPermission || params.data.payment_type !== PaymentTypes.RoomTransfer), field: 'color', filter: false, cellRenderer: 'DisplayColor', cellEditorPopup: true, cellEditorPopupPosition: 'under',  cellEditorFramework: 'colorSelectorCell'},
                    { headerName: this.$t('table_fields.active'), editable: params => hasPermission && (hasTierPermission || params.data.payment_type !== PaymentTypes.RoomTransfer), field: 'active', filter: true, floatingFilterComponentParams: {type: 'select', values: [{name: this.$t('general.yes'), value: true}, {name: this.$t('general.no'), value: false}], selectValue: 'value'}, cellRenderer: 'DisplayActive', cellEditorFramework: 'activeEditor' }
                ]

                if (hasPermission) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'),
                          cellStyle: params => {
                              if (!(hasPermission && (hasTierPermission || params.data.payment_type !== PaymentTypes.RoomTransfer))) {
                                  return {display: 'none'}
                              }
                              return null
                          },
                          editable: false,
                          filter: false,
                          sortable: false,
                          colId:'actions',
                          cellRenderer: 'btnCellRenderer',
                          minWidth: 150}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                paymentTypes: [],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/payment_types/')
                    this.paymentTypes = response.data ?? []
                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/payment_types/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/payment_types/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
