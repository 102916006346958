export const WizardRequirements = {
    warehouses   : 'warehouses',
    businessUnits : 'business_units',
    vat  : 'vat',
    ingredientCategories: 'ingredient_categories',
    ingredientSubCategories: 'ingredient_sub_categories',
    ingredients: 'ingredients',
    categories: 'categories',
    subcategories: 'subcategories',
    products: 'products',
    orderOptions: 'order_options',
    productDisplayGroups: 'product_display_groups',
    discounts: 'discounts',
    paymentTypes: 'payment_types',
    buyers: 'buyers',
    suppliers: 'suppliers',
    logos: 'logos',
    printers: 'printers',
    furs: 'furs',
    workPremises: 'work_premises',
    permissions: 'permissions',
    employees: 'employees',
    invoiceConfig: 'invoice_config'
}

export const setupProgress = {
    NONE: 0,
    REQUIRED: 1,
    FULL: 2
}