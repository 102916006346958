<template>
    <b-modal v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <draggable v-model="products">
                <div style="cursor: pointer;" v-for="(product, index) in products" :key="product.id" class="mt-1 mb-1">
                    <fa icon="arrows-up-down" color="#B4BFB5" />
                    <span :class="{'disabled-product': product.disabled}">
                        {{index + 1}} - {{product.name}}
                        <span v-if="product.disabled" class="disabled" >{{$t('keys.products.disabled')}}</span>
                    </span>
                </div>
            </draggable>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="orderByName">
                <feather-icon icon="MoveIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('keys.product_display_groups.order_by_name') }}</span>
            </b-button>
            <b-button variant="primary" @click="save">
                <feather-icon icon="SaveIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('general.save') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BModal} from 'bootstrap-vue'
    import draggable from 'vuedraggable'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BModal,
            BButton,
            draggable
        },
        data() {
            return {
                modalActive: false,
                products: [],
                displayGroupId: null
            }
        },
        methods: {
            async open(displayGroupId) {
                this.displayGroupId = displayGroupId

                try {
                    const response = await this.$http.get(`/api/client/v1/product_display_groups/${  displayGroupId  }/products`)
                    this.products = response.data
                    this.modalActive = true
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            orderByName() {
                this.products.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                })
            },
            async save() {
                try {
                    await this.$http.put(`/api/client/v1/product_display_groups/${  this.displayGroupId  }/sort`, this.products.map(product => product.id))
                    this.modalActive = false
                    this.$emit('itemsSaved')
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            }
        }
    }
</script>

<style scoped>
.disabled-product {
    color: red;
}
.disabled {
    font-size: 0.7rem;
}
</style>