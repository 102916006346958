<template>
    <div class="itemCard d-flex flex-column justify-content-center unselectable"
         :style="`background-color: ${backgroundColor}; color: ${labelColor}`">
        <div  v-if="!showPrice" class="flex items-center justify-content-center">
            <div class="p-2 text-center centerLabel" style="text-overflow: ellipsis;">
                <div class="line-clamp-2">
                    {{ topLabel }}
                </div>
            </div>
        </div>
        <template v-else>

            <span class=" label topLabel py-1 px-2"
                  :style="`background-color: ${topLabelBackground};color:${topLabelBackground ? 'white' : 'initial'}`">{{topLabel}} </span>

            <span class="label bottomLabel py-1 px-2"
                  :style="`background-color: ${bottomLabelBackground}`">{{ bottomLabel }} {{ bottomLabelAppend }}</span>

        </template>
    </div>
</template>
<script>
    export default {
        props: {
            topLabel: {
                type: String
            },
            bottomLabel: {
                type: String
            },
            bottomLabelAppend: {
                type: String
            },
            centerLabel: {
                type: String
            },

            backgroundColor: {
                type: String,
                default: '#ffffff'
            },
            topLabelBackground: {
                type: String
            },
            bottomLabelBackground: {
                type: String
            },
            showPrice: {
                type: Boolean
            },
            labelColor: {
                type: String
            }
        }
    }
</script>
<style scoped>
.itemCard {
  height: 9vh;
  min-height: 50px;
  text-align: left;
  border-radius: 18px;
  cursor: pointer;
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 500;
}
.label {
  line-height: 1.1rem;
  letter-spacing: 1px !important;
}
.topLabel {
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-overflow: clip;
}
.bottomLabel {
  font-size: 0.8rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bold;
}
.centerLabel {
  text-transform: uppercase;
  letter-spacing: normal;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media only screen and (min-width: 1280px) and (max-width: 1600px) {
.itemCard{
    font-size: 14px;
  }
}
@media only screen and (max-width: 640px) {
  .itemCard{
    font-size: 14px;
  }
}
</style>