<template>
    <b-modal :title="$t('keys.products.where_to_copy_data_to')" v-model="modalActive"  hide-footer no-close-on-backdrop>

        <v-select class="mt-2" v-model="selected" appendToBody label="name" :options="activeBusinessUnits" :reduce="item => item.id" :clearable="false"/>

        <div class="d-flex justify-content-end">
            <b-button class="mt-2" variant="primary" @click="confirm">{{$t('general.accept')}}</b-button>
        </div>

    </b-modal>
</template>

<script>

    import {BModal, BButton} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import vSelect from 'vue-select'

    export default {
        components: {vSelect, BModal, BButton},
        data() {
            return {
                businessUnits: [],
                selected: null,
                modalActive: false
            }
        },
        computed: {
            activeBusinessUnits() {
                return this.businessUnits.filter(ele => ele.id !== this.id)
            }
        },
        props:{
            id: {
                type: String,
                default: ''
            }
        },
        methods: {
            open() {
                this.modalActive = true

                if (this.selected === this.id) {
                    if (this.activeBusinessUnits.length > 0) {
                        this.selected = this.activeBusinessUnits[0].id
                    }
                }
            },
            confirm() {
                this.$emit('confirm', this.selected)
                this.modalActive = false
            },
            async loadData() {
                try {
                    const res = await this.$http.get('/api/client/v1/business_units/')
                    this.businessUnits = res.data ?? []

                    if (this.businessUnits.length > 0) {
                        this.selected = this.businessUnits[0].id
                    }
                } catch (err) {
                    Sentry.captureException(err)
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>