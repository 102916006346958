<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.subcategories')">
            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.ProductsWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.subcategories.add_subcategory') }}
                </b-button>
            </div>
            <Table v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="productSubcategories" @deleteRow="remove" @editRow="editRow" @edit="edit"/>
        </b-card>
        <AddProductSubcategory ref="addModal" :productCategories="productCategories" :productOrderOptions="productOrderOptions" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddProductSubcategory from '@/views/ProductSubcategory/AddProductSubcategory'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        components: {Table, AddProductSubcategory, BCard, BOverlay, BButton},
        computed: {
            columnDefs() {
                const hasPermissions = this.$hasPermission(this.$permissions.ProductsWrite)

                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: hasPermissions,  field: 'name', filter: true},
                    { headerName: this.$t('table_fields.category'), editable: hasPermissions, field: 'main_category_id', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.productCategories, multiple: true} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.productCategories} }, cellRenderer: (params) => this.getCategoryName(params.value)},
                    { headerName: this.$t('table_fields.additions'), editable: hasPermissions, field: 'order_option_ids', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.productOrderOptions, multiple: true} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.productOrderOptions, multiple: true, selectValue: 'id', findValueInArray: true} }, cellRenderer: (params) => this.getAdditionName(params.value)}
                ]

                if (this.$hasPermission(this.$permissions.ProductsWrite)) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                productCategories: [],
                productSubcategories: [],
                productOrderOptions: [],
                showLoader: false,
                arraysLoad: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/product_categories/')
                    const response2 = this.$http.get('/api/client/v1/product_subcategories/')
                    const response3 = this.$http.get('/api/client/v1/product_order_options/')

                    const responses = await Promise.all([response1, response2, response3])
                    this.productCategories = responses[0].data ?? []
                    this.productSubcategories = responses[1].data ?? []
                    this.productOrderOptions = responses[2].data ?? []
                    await this.itemChange()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/product_subcategories/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/product_subcategories/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                    this.itemChange()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getCategoryName(value) {
                if (value) {
                    const item = this.productCategories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            getAdditionName(value) {
                if (value && value.length > 0) return value.filter(opt => {
                    const item = this.productOrderOptions.find(x => x.id === opt)
                    return !!item
                }).map(option => {
                    const item = this.productOrderOptions.find(x => x.id === option)
                    if (item) return item.name
                }).join(', ')
                return '/'
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
