<template>
    <b-modal :title="$t('general.employees')" v-model="modalActive" no-close-on-backdrop>
        <template #modal-header>
            <b-tabs-header :tabs="titles" @clickTab="handleClickTab" :activeTab.sync="activeTab" @close="modalActive = false" />
        </template>
        <template #default>
            <div>
                <validation-observer ref="validation">
                    <div v-if="activeTab === 0">
                        <b-form-group :label="$t('form_data.person_name')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.person_name')" rules="required">
                                <b-form-input ref="name" autofocus v-model="addObject.name"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('keys.employees.print_name')">
                            <validation-provider #default="{ errors }" :name="$t('keys.employees.print_name')" rules="required">
                                <b-form-input v-model="addObject.print_name"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('general.tax_number')" v-if="!addObject.is_employee_foreign">
                            <validation-provider #default="{ errors }" :name="$t('general.tax_number')" rules="required|alpha_num|taxNew"><b-form-input type="text" v-model="addObject.tax_number"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('keys.pin')">
                            <validation-provider #default="{ errors }" :name="$t('keys.pin')" rules="required|alpha_num">
                                <b-form-input v-model="addObject.access_pin"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('keys.foreign_employee')">
                            <b-form-checkbox switch class="w-100 active-editor" ref="input"
                                             v-model="addObject.is_employee_foreign" @change="addObject.tax_number = null"></b-form-checkbox>
                        </b-form-group>
                        <b-form-group :label="$t('keys.permissions.permissions')">
                            <validation-provider #default="{ errors }" :name="$t('keys.permissions.permissions')" rules="required">
                                <v-select v-model="addObject.employee_permission_group_id" appendToBody label="name"
                                          :options="employeePermissionGroups" :reduce="item => item.id"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div v-if="activeTab === 1">
                        <b-form-group :label="$t('keys.can_login_to_all_business_units')">
                            <b-form-checkbox switch v-model="addObject.can_login_to_all_business_units"></b-form-checkbox>
                        </b-form-group>
                        <b-form-group v-if="!addObject.can_login_to_all_business_units" :label="$t('keys.allowed_business_units_login')">
                            <validation-provider #default="{ errors }" :name="$t('keys.allowed_business_units_login')" rules="required">
                                <v-select v-model="addObject.allowed_business_units" multiple appendToBody label="name"
                                          :options="businessUnits" :reduce="item => item.id"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('menu.locales.language')">
                            <validation-provider #default="{ errors }" :name="$t('menu.locales.language')" >
                                <v-select  :getOptionLabel="item => item.id" v-model="addObject.language" :options="localeObjects" :reduce="item => item.id" :clearable="true" appendToBody>
                                    <template #selected-option="{name,img}" style="max-width: 90%;" >
                                        <div>
                                            <b-img height="14px" width="22px" :src="img"/>
                                            {{name}}
                                        </div>
                                    </template>
                                    <template v-slot:option="option">
                                        <b-img height="14px" width="22px" :src="option.img"/>
                                        {{option.name}}
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div v-if="activeTab === 2">
                        <b-form-group :label="$t('form_data.address')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.address')">
                                <b-form-input ref="address" autofocus v-model="contact.address"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.country')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.country')">
                                <b-form-input v-model="contact.country"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.email')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.email')">
                                <b-form-input v-model="contact.email"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.phone')" style="position: relative;">
                            <span style="position: absolute; right: 10px; margin-top: 10px;">
                                <fa class="ml-1" id="supported-phone-formats-tooltip" icon="question-circle" color="#72a5d8" />
                                <b-tooltip target="supported-phone-formats-tooltip" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                                    {{$t('general.supported_formats')}}:<br>
                                    <span v-html="$supported_phone_formats"/>
                                </b-tooltip>
                            </span>
                            <validation-provider #default="{ errors }" :name="$t('form_data.phone')" rules="phone_number">
                                <b-form-input v-model="contact.phone"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                </validation-observer>
            </div>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormCheckbox, BTooltip, BImg} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {required, alphaNum, taxNew} from '@validations'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'
    import {getLocaleObjectsCashRegister} from '@/libs/enums/Locales'
    import BTabsHeader from '@/views/components/BTabsHeader.vue'

    export default {
        components: {
            BTabsHeader,
            BTooltip,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            BFormCheckbox,
            BImg
        },
        props: {
            employeePermissionGroups: {
                type: Array
            }
        },
        data() {
            return {
                activeTab: 0,
                modalActive: false,
                addObject: {
                    name: '',
                    print_name: '',
                    tax_number: '',
                    access_pin: '',
                    is_employee_foreign: '',
                    employee_permission_group_id: '',
                    can_login_to_all_business_units: true,
                    allowed_business_units: []
                },
                contact: {
                    address: '',
                    country: '',
                    email: '',
                    phone: ''
                },
                required,
                alphaNum,
                taxNew,
                editing: false,
                businessUnits: []
            }
        },
        computed: {
            titles() {
                return [{title: this.$t('general.employees')}, {title: this.$t('keys.advanced')}, {title: this.$t('keys.contact')}]
            },
            localeObjects() {
                return getLocaleObjectsCashRegister()
            }
        },
        methods: {
            handleClickTab(index) {
                this.activeTab = index
                if (index === 0) {
                    this.focusName()
                } if (index === 2) {
                    this.focusAddress()
                }
            },
            async focusName() {
                await this.$nextTick()
                this.$refs.name.focus()
            },
            async focusAddress() {
                await this.$nextTick()
                this.$refs.address.focus()
            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = JSON.parse(JSON.stringify(data))
                    this.contact = this.addObject.contact
                    this.editing = true
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        print_name: '',
                        tax_number: '',
                        access_pin: '',
                        employee_permission_group_id: '',
                        is_employee_foreign: false,
                        can_login_to_all_business_units: true,
                        allowed_business_units: [],
                        language: ''
                    }
                    this.contact = {
                        address: '',
                        country: '',
                        email: '',
                        phone: ''
                    }
                }
                this.loadData()
                this.modalActive = true
                this.activeTab = 0
            },
            async loadData() {
                try {
                    const respBusiness = await this.$http.get('/api/client/v1/business_units/')
                    this.businessUnits = respBusiness.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            validate() {
                this.$refs.validation.validate().then((success) => {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    if (this.addObject.is_employee_foreign) {
                        this.addObject.tax_number = ''
                    }
                    this.addObject.tax_number.toUpperCase()
                    this.addObject.contact = this.contact

                    const response = await this.$http.post('/api/client/v1/employees/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        print_name: '',
                        tax_number: '',
                        access_pin: '',
                        employee_permission_group_id: '',
                        is_employee_foreign: false,
                        can_login_to_all_business_units: true,
                        allowed_business_units: [],
                        language: ''
                    }
                    this.contact = {
                        address: '',
                        country: '',
                        email: '',
                        phone: ''
                    }
                    this.tax = ''
                } catch (err) {

                    if (err && err.response && err.response.data && (err.response.data.error === 'pin already exists')) {
                        this.$printError(this.$t('print.warning.pin_already_exists'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'tax number already exists')) {
                        this.$printError(this.$t('print.warning.tax_number_already_exists'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_edit'))
                    }
                }
            },
            async edit() {
                try {
                    if (this.addObject.is_employee_foreign) {
                        this.addObject.tax_number = ''
                    }

                    this.addObject.contact = this.contact

                    await this.$http.put(`/api/client/v1/employees/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        print_name: '',
                        tax_number: '',
                        access_pin: '',
                        employee_permission_group_id: '',
                        is_employee_foreign: false,
                        can_login_to_all_business_units: true,
                        allowed_business_units: [],
                        language: ''
                    }
                    this.contact = {
                        address: '',
                        country: '',
                        email: '',
                        phone: ''
                    }
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'pin already exists')) {
                        this.$printError(this.$t('print.warning.pin_already_exists'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'tax number already exists')) {
                        this.$printError(this.$t('print.warning.tax_number_already_exists'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_edit'))
                    }

                }
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>

</style>
