<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.buyers')">

            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.GeneralWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.buyers.add_buyer') }}
                </b-button>
            </div>

            <Table v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="buyers" @deleteRow="remove" @editRow="editRow" @edit="edit"/>
        </b-card>

        <AddBuyer ref="addModal" :predefinedDiscounts="predefinedDiscounts" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddBuyer from '@/views/Buyer/AddBuyer'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import {validatorTAX} from '@core/utils/validations/validators'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        components: {Table, AddBuyer, BCard, BOverlay, BButton},
        computed: {

            columnDefs() {
                const hasPermission = this.$hasPermission(this.$permissions.GeneralWrite)

                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: hasPermission, field: 'name', filter: true},
                    { headerName: this.$t('table_fields.address'), editable: hasPermission, field: 'address', filter: true},
                    { headerName: this.$t('table_fields.country'), editable: hasPermission, field: 'country', filter: true},
                    { headerName: this.$t('table_fields.tax_number'), editable: hasPermission, field: 'tax_number', filter: true},
                    { headerName: this.$t('table_fields.predefined_discount'), editable: hasPermission, field: 'predefined_discount_id', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.predefinedDiscounts} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.predefinedDiscounts} }, cellRenderer: (params) => this.getVatName(params.value)},
                    { headerName: this.$t('table_fields.contact_person'), editable: hasPermission, field: 'contact', filter: true, cellEditorFramework: 'editContact', cellRenderer: (params) => this.getContact(params.value)},
                    { headerName: this.$t('table_fields.additional_info'), editable: hasPermission, field: 'additional_info', filter: true},
                    { headerName: this.$t('table_fields.days_until_deadline'), editable: hasPermission, field: 'payment_deadline', filter: true, floatingFilterComponentParams: {type: 'payment_deadline'}, cellRenderer: 'DisplayNumber', cellEditorFramework: 'numberEditor', cellEditorParams: {type: 'payment_deadline'} }
                ]

                if (hasPermission) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                buyers: [],
                predefinedDiscounts: [],
                showLoader: false,
                arraysLoad: false,
                defaultPaymentDeadline: 0
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/buyers/')
                    const response2 = this.$http.get('/api/client/v1/predefined_discounts/')
                    const response3 = this.$http.get('/api/client/v1/invoice_config/')

                    const responses = await Promise.all([response1, response2, response3])

                    this.predefinedDiscounts = responses[1].data ?? []
                    this.defaultPaymentDeadline = responses[2].data.number_of_days ?? []
                    this.buyers = (responses[0].data ?? []).map(buyer => {
                        buyer.predefined_discount_id = (buyer.predefined_discount_id === this.$NilObjectId) ? null : buyer.predefined_discount_id
                        buyer.payment_deadline = (!buyer.payment_deadline || buyer.payment_deadline === 0) ? this.defaultPaymentDeadline : buyer.payment_deadline
                        return buyer
                    })

                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    if (!validatorTAX(params.data.tax_number)) {
                        this.$printError(this.$t('print.error.invalid_vat_number'))
                        return
                    }

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/buyers/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'pin already exists')) {
                        this.$printSuccess(this.$t('print.warning.pin_already_exists'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'tax number already exists')) {
                        this.$printSuccess(this.$t('print.warning.tax_number_already_exists'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'Buyer is not valid')) {
                        this.$printError(this.$t('print.error.buyer_data_not_valid'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_edit'))
                    }
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/buyers/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getContact(contact) {
                if (contact) return `${contact.name}`
                else return '/'
            },
            getVatName(value) {
                if (value) {
                    const item = this.predefinedDiscounts.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
