<template>
    <b-modal :title="$t('course_menu.course_menu')" v-model="modalActive" size="lg" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('form_data.name2')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.name2')" rules="required">
                        <b-form-input autofocus v-model="addObject.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('form_data.short_label')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.short_label')" :rules="`required|max:${3}`">
                        <b-form-input  v-model="addObject.short_label"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@core/utils/validations/validations'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            productsRaw: {
                type: Array
            },
            selectedBusinessUnitId: {
                tyep: String
            }
        },
        data() {
            return {
                arraysLoad: false,
                modalActive: false,
                addObject: {
                    name: '',
                    short_label:'',
                    index:0
                },
                required,
                editing: false
            }
        },
        methods: {
            open(data = null) {
                if (data !== null) {
                    this.addObject = data
                    this.editing = true
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        short_label:'',
                        index:0
                    }
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    this.addObject.business_unit_id = this.selectedBusinessUnitId
                    const response = await this.$http.put('/api/client/v1/course_menu/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        short_label:'',
                        index:0
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async edit() {
                try {
                    await this.$http.put(`/api/client/v1/course_menu/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        short_label:'',
                        index:0
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            }
        }
    }
</script>
