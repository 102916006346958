<template>
    <b-modal :title="$t('course_menu.courses_configuration')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <draggable v-model="courseMenus">
                <div style="cursor: pointer;" v-for="(courseMenu, index) in courseMenus" :key="courseMenu.id" class="mt-1 mb-1">
                    <fa class="fa-thin" icon="arrows-up-down" color="#B4BFB5" />
                    {{index + 1}} - {{courseMenu.name}}
                </div>
            </draggable>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="save">
                <feather-icon icon="SaveIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('general.save') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BModal} from 'bootstrap-vue'
    import draggable from 'vuedraggable'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BModal,
            BButton,
            draggable
        },
        data() {
            return {
                modalActive: false,
                courseMenus: []
            }
        },
        methods: {
            open(courseMenus) {
                this.courseMenus = courseMenus
                this.modalActive = true
            },
            async save() {
                try {
                    await this.$http.put('/api/client/v1/course_menu/sort', this.courseMenus.map(cm => cm.id))
                    this.modalActive = false
                    this.$emit('courseMenusSaved')
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            }
        }
    }
</script>
