<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.product_display_groups')">
            <b-form-group :label="$t('general.business_unit')"  v-if="businessUnits.length > 0" >
                <v-select :clearable="false" v-model="selectedBussinesUnit" :options="businessUnits" label="name" class="w-100 mb-2" @input="loadSelectedbusinessUnitData"/>
            </b-form-group>
            <b-alert show  v-else-if="!showLoader" variant="warning" class="p-1 mt-2">
                {{$t('keys.table_empty')}} <router-link to="business_units">{{$t('keys.here')}}</router-link>
            </b-alert>

            <div class="d-flex flex-wrap justify-content-between" style="gap: 1rem;" v-if="$hasPermission($permissions.ProductsWrite)">
                <b-button variant="primary" @click="$refs.copyToAnotherBusinessUnit.open()" :disabled="businessUnits && businessUnits.length <= 1" class="mr-2">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.product_display_groups.copy_to_another_business_unit') }}
                </b-button>
                <div class="d-flex flex-wrap" style="gap: 0.5rem;">
                    <b-button variant="primary" @click="$refs.addModal.open()" :disabled="businessUnits && businessUnits.length === 0" class="mr-2">
                        <feather-icon icon="PlusSquareIcon"/>
                        {{ $t('keys.product_display_groups.add_product_display_group') }}
                    </b-button>
                    <b-button variant="primary" @click="openOrderingModal" :disabled="businessUnits && businessUnits.length === 0" class="mr-2">
                        <feather-icon icon="MoveIcon"/>
                        {{ $t('general.change_order') }}
                    </b-button>
                    <b-button variant="primary" @click="openPreview" :disabled="businessUnits && businessUnits.length === 0">
                        <feather-icon icon="EyeIcon"/>
                        {{ $t('general.preview') }}
                    </b-button>
                </div>
            </div>

            <Table v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="productDisplayGroups" @deleteRow="remove" @editRow="editRow" @edit="edit" @changeOrder="openProductDisplayGroupOrderingModal"/>
        </b-card>

        <div v-if="selectedBussinesUnit">
            <AddProductDisplayGroup ref="addModal" :productsRaw="products" v-on:itemAdded="loadData" :selectedBusinessUnitId="selectedBussinesUnit.id"/>
            <SortProductDisplayGroupModal ref="orderingModal" v-on:groupsSaved="loadData"/>
            <SortProductDisplayGroupProductsModal ref="displayGroupOrderingModal"/>
            <CopyToAnotherBusinessUnit v-if="businessUnits.length >= 2" ref="copyToAnotherBusinessUnit" :id="selectedBussinesUnit.id" @confirm="copyDataToBusinessUnit"/>
            <PreviewModal ref="previewModal" />
        </div>

    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddProductDisplayGroup from '@/views/ProductDisplayGroup/Components/AddProductDisplayGroup.vue'
    import {BButton, BCard, BOverlay, BAlert, BFormGroup} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import SortProductDisplayGroupModal from '@/views/ProductDisplayGroup/Components/SortProductDisplayGroupModal.vue'
    import SortProductDisplayGroupProductsModal from '@/views/ProductDisplayGroup/Components/SortProductDisplayGroupProductsModal.vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'
    import CopyToAnotherBusinessUnit from '@/components/CopyToAnotherBusinessUnit.vue'
    import PreviewModal from '@/views/ProductDisplayGroup/Components/PreviewModal.vue'

    export default {
        mixins: [itemChange],
        components: {
            CopyToAnotherBusinessUnit,
            SortProductDisplayGroupProductsModal,
            SortProductDisplayGroupModal,
            PreviewModal,
            Table,
            AddProductDisplayGroup,
            BAlert,
            BCard,
            BOverlay,
            BButton,
            vSelect,
            BFormGroup
        },
        computed: {
            columnDefs() {
                const hasPermissions = this.$hasPermission(this.$permissions.ProductsWrite)

                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: hasPermissions, field: 'name', filter: true},
                    { headerName: this.$t('table_fields.color'), editable: hasPermissions, field: 'color', filter: false, cellRenderer: 'DisplayColor', cellEditorPopup: true, cellEditorPopupPosition: 'under',  cellEditorFramework: 'colorSelectorCell'},
                    { headerName: this.$t('table_fields.products'), editable: false, field: 'product_ids', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.products, multiple: true} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.products, multiple: true, selectValue: 'id', findValueInArray: true} }, cellRenderer: (params) => this.getProductsNames(params.value)}
                ]

                if (hasPermissions) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 300, cellRendererParams: {button: 'ChangeOrder'}}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                productDisplayGroups: [],
                products: [],
                showLoader: true,
                arraysLoad: false,
                businessUnits: [],
                selectedBussinesUnit: null,
                productDisplayGroupsOrdering: null
            }
        },
        methods: {
            setProductsIncluded() {
                let productsInUse = this.products
                productsInUse = productsInUse.map((product) => {
                    let includes = false
                    for (const dg of this.productDisplayGroups) {
                        if (dg.product_ids.includes(product.id)) {
                            includes = true
                        }
                    }

                    return {
                        ...product,
                        included: includes
                    }
                })

                return productsInUse.sort((a, b) => {
                    if (a.included && !b.included) {
                        return 1
                    } else if (!a.included && b.included) {
                        return -1
                    } else {
                        return 0
                    }
                })
            },
            async copyDataToBusinessUnit(id) {
                try {
                    await this.$http.post(`/api/client/v1/product_display_groups/copy_to_business_unit/${this.selectedBussinesUnit.id}/${  id}`)

                    const item = this.businessUnits.find(ele => ele.id === id)

                    if (item) {
                        this.selectedBussinesUnit = item
                        this.loadSelectedbusinessUnitData()
                    }

                    this.$printSuccess(this.$t('print.success.copied'))

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            },
            openOrderingModal() {
                this.$refs.orderingModal.open(this.productDisplayGroups.map(displayGroup => { return {id: displayGroup.id, name: displayGroup.name} }))
            },
            openPreview() {
                this.$refs.previewModal.open(this.selectedBussinesUnit.id)
            },
            async openProductDisplayGroupOrderingModal(displayGroupId) {

                this.showLoader = true
                await this.$refs.displayGroupOrderingModal.open(displayGroupId)
                this.showLoader = false
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/products/')
                    const response2 = this.$http.get('/api/client/v1/business_units/')
                    

                    const responses = await Promise.all([response1, response2])
                    this.products = responses[0].data ?? []
                    this.businessUnits = responses[1].data ?? []

                    await this.itemChange()

                    if (this.businessUnits && this.businessUnits.length > 0 && !this.selectedBussinesUnit) {
                        const response = await this.$http.get(`/api/client/v1/product_display_groups/business_units/${ this.businessUnits[0].id}`)
                        this.productDisplayGroups = response.data

                        this.selectedBussinesUnit = this.businessUnits[0]
                    } else if (this.businessUnits && this.businessUnits.length > 0) {
                        const response = await this.$http.get(`/api/client/v1/product_display_groups/business_units/${ this.selectedBussinesUnit.id}`)
                        this.productDisplayGroups = response.data
                    }

                    this.products = this.setProductsIncluded()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async loadSelectedbusinessUnitData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get(`/api/client/v1/product_display_groups/business_units/${ this.selectedBussinesUnit.id}`)       
                    this.productDisplayGroups = response.data ?? []

                    this.products = this.setProductsIncluded()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/product_display_groups/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/product_display_groups/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getProductsNames(value) {
                if (value && value.length > 0) return value.filter(opt => {
                    const item = this.products.find(x => x.id === opt && !x.disabled)
                    return !!item
                }).map(option => {
                    const item = this.products.find(x => x.id === option && !x.disabled)
                    if (item) return item.name
                }).join(', ')
                return '/'
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
