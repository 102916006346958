<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.ingredients')">
            <div class="d-flex justify-content-end">
                <b-form-checkbox class="mr-2 my-auto h-full pt-0.5 disabled-items" switch v-model="showDisabled">{{ $t('keys.ingredients.show_disabled_ingredients')}}</b-form-checkbox>
                <div v-if="$hasPermission($permissions.ProductsWrite)">
                    <b-button variant="primary" @click="$refs.addModal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        {{ $t('general.add_ingredient') }}
                    </b-button>
                </div>
            </div>

            <Table v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="ingredients" @enable="enable" @disable="disable" @clone="clone" @editRow="editRow" @edit="edit" @generateEAN="generateEAN"/>
        </b-card>

        <AddIngredient ref="addModal" :ingredients="ingredientsRaw" v-on:itemAdded="loadData" :ingredient_categories="ingredient_categories"/>
        <UsedAtModal ref="usedAtModal" @confirm="confirmUsedAt" @cancel="cancelUsedAt">
            <p v-if="productsWithIngredient">
                {{$t('general.ingredient')}} <b>{{this.ingredientName}}</b> {{$t('keys.ingredients.disable_ingredient_middle')}}
                (<b>{{productsWithIngredient.length}}</b>) {{$t('keys.ingredients.disable_ingredient_end')}}.
            </p>
            <p v-if="productsWithIngredient && productsWithIngredient.length > 0">
                {{$t('keys.ingredients.products_list')}}:
            </p>
            <div v-if="productsWithIngredient">
                <ul>
                    <li v-for="item in productsWithIngredient.slice(0,5)" :key="item.id">{{ item.name }}</li>
                    <li v-if="productsWithIngredient.length > 5">...</li>
                </ul>
            </div>
            <p>
                {{$t('keys.ingredients.ingredient_disable_info')}}.
            </p>
            <p>
                {{$t('general.wish_continue')}}
            </p>
        </UsedAtModal>
        <b-modal size="lg" ref="ean">
            <b-overlay :show="!loaded">
                <iframe :src="this.ean" style="width:100%; height: 500px;" @load="loaded = true" />
            </b-overlay>
        </b-modal>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import {BButton, BCard, BFormCheckbox, BOverlay, BModal} from 'bootstrap-vue'
    import AddIngredient from '@/views/Ingredient/AddIngredient'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'
    import UsedAtModal from '@/views/components/UsedAtModal.vue'
    import {getAmountTypes} from '@/libs/constants/ingredients.js'

    export default {
        mixins: [itemChange],
        components: {
            BModal,
            UsedAtModal,
            BFormCheckbox,
            AddIngredient,
            Table,
            BCard,
            BOverlay,
            BButton},
        data() {
            return {
                ingredientsRaw: [],
                ingredient_categories:[],
                showLoader: false,
                arraysLoad: false,
                showDisabled: false,
                selectedObj: null,
                selectedId: null,
                ingredientName: '',
                productsWithIngredient: null,
                ean: '',
                loaded: false
            }
        },
        methods: {
            generateEAN(item) {
                this.loaded = false
                this.ean = `/api/client/v1/ingredients/${item.id}/ean`
                this.$refs.ean.show()
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response0 = this.$http.get('/api/client/v1/ingredients/')
                    const response1 = this.$http.get('/api/client/v1/ingredient_subcategories/')

                    const responses = await Promise.all([response0, response1])
                    this.ingredientsRaw = responses[0].data ?? []
                    this.ingredient_categories = responses[1].data ?? []
                    await this.itemChange()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            clone(data) {
                this.$refs.addModal.open(data, true)
            },
            async confirmUsedAt() {
                this.showLoader = true
                try {
                    await this.$http.patch(`/api/client/v1/ingredients/${this.selectedId}`, this.selectedObj)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    await this.loadData()
                    this.selectedObj = null
                    this.selectedId = null
                    this.showLoader = false
                }
            },
            cancelUsedAt() {
                this.selectedObj = null
                this.selectedId = null
            },
            enable(params) {
                const main = {
                    data: params,
                    column: {
                        colId: 'disabled'
                    },
                    newValue: false
                }

                this.edit(main)
            },
            disable(params) {
                const main = {
                    data: params,
                    column: {
                        colId: 'disabled'
                    },
                    newValue: true
                }

                this.edit(main)
            },
            async edit(params) {
                try {
                    const editObject = {object_data:{[params.column.colId]: params.newValue}}

                    if (editObject.object_data.disabled) { // if we are disabling an ingredient
                        const response = await this.$http.get(`/api/client/v1/products/with_ingredients/${params.data.id}`)
                        this.productsWithIngredient = response.data ?? []

                        this.ingredientName = params.data.name
                        this.selectedId = params.data.id
                        this.selectedObj = editObject

                        this.$refs.usedAtModal.show()
                    } else {
                        await this.$http.patch(`/api/client/v1/ingredients/${params.data.id}`, editObject)
                        this.$printSuccess(this.$t('print.success.edit'))

                        await this.loadData()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            amountType(value) {
                if (value) {
                    const item = this.amountTypes.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            ingredientCategoryName(value) {
                if (value) {
                    const item = this.ingredient_categories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            }
        },
        computed: {
            amountTypes() {
                return getAmountTypes()
            },
            columnDefs() {
                const hasPermissions = this.$hasPermission(this.$permissions.ProductsWrite)

                const defs = [
                    { headerName: this.$t('table_fields.ingredient_code'), field: 'ingredient_code',  filter: true, editable: false},
                    { headerName: this.$t('table_fields.name'), field: 'name', filter: true, editable: hasPermissions},
                    { headerName: this.$t('table_fields.amount_type'), field: 'amount_type', filter: true, floatingFilterComponentParams:  {type: 'select', values: this.amountTypes, selectValue: 'id'}, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.amountTypes} }, cellRenderer: (params) => this.amountType(params.value), editable: hasPermissions},
                    { headerName: this.$t('table_fields.category'), field: 'ingredient_category_id', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.ingredient_categories} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.ingredient_categories} }, cellRenderer: (params) => this.ingredientCategoryName(params.value), editable: hasPermissions}
                ]

                if (this.$hasPermission(this.$permissions.ProductsWrite)) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 220, cellRendererParams: {button: 'Clone'}}
                    )
                }

                return defs
            },
            ingredients() {
                const ingredients = this.ingredientsRaw
                if (this.showDisabled) {
                    return ingredients
                }

                return ingredients.filter((x) => !x.disabled)
            }
        },

        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
