import { render, staticRenderFns } from "./ProductDisplayGroups.vue?vue&type=template&id=614016da&scoped=true&"
import script from "./ProductDisplayGroups.vue?vue&type=script&lang=js&"
export * from "./ProductDisplayGroups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "614016da",
  null
  
)

export default component.exports