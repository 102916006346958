<template>
    <b-modal :title="$t('config.logos.logo')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('form_data.name')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                        <b-form-input v-model="displayName" :disabled="fileUploading"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('config.logos.logo')">
                    <validation-provider #default="{ errors }" :name="$t('config.logos.logo')" :rules="getLogoFileType">
                        <b-form-file
                            accept=".webp, .jpg, .jpeg, .png, .gif"
                            v-model="imageData"
                            :disabled="fileUploading"
                            :placeholder="`${$t('config.choose_file')}...`"
                            :drop-placeholder="`${$t('config.drop_file')}...`"
                            :browse-text="`${$t('config.browse')}...`"
                            @change="loadImageOnCanvas"
                        />
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <div v-if="originalImage && validateImageFileType">
                    <div>{{ $t('general.preview') }}</div>
                    <b-form-input :disabled="fileUploading" min="0" max="255" v-model="inputValue" type="range" @update="thresholding"></b-form-input>
                </div>
                <div ref="divImageCanvas" class="w-100 d-flex justify-content-center">
                    <canvas class="" ref="tresholdImageCanvas" style="max-width: 50%; max-height: 300px;">

                    </canvas>
                </div>
            </validation-observer>

            <b-progress v-if="fileUploading" class="mt-1" :value="progress" :max="100" show-progress></b-progress>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BFormFile, BButton, BFormGroup, BFormInput, BModal, BProgress} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, imageFile } from '@validations'
    export default {
        components: {
            BFormFile,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BProgress,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                imageWidth: 0,
                imageHeight: 0,
                inputValue: 128,
                originalImage: null,
                formData: null,
                imageData: null,
                modalActive: false,
                required,
                imageFile,
                discount: '',
                editing: false,
                progress : 0,
                displayName: '',
                fileUploading: false,
                imageExt: ''
            }
        },
        methods: {
            loadImageOnCanvas(data) {
                this.inputValue = 128
                if (!(data && data.target && data.target.files && data.target.files.length > 0)) {
                    return
                }

                const image = data.target.files[0]
                const ctx = this.$refs.tresholdImageCanvas.getContext('2d')
                const canvas = this.$refs.tresholdImageCanvas

                const img = new Image()
                img.src = URL.createObjectURL(image)
                this.originalImage = URL.createObjectURL(image)

                const thisIns = this

                img.onload = async function() {

                    thisIns.imageHeight = img.height
                    thisIns.imageWidth = img.width

                    const width = img.width
                    const height = img.height


                    canvas.width = width
                    canvas.height = height

                    await ctx.drawImage(img, 0, 0)
                    const imageTmp = ctx.getImageData(0, 0, img.width, img.height)

                    thisIns.originalImage = imageTmp

                    thisIns.thresholding(128)
                }
            },
            thresholding(value) {
                const ctx = this.$refs.tresholdImageCanvas.getContext('2d')

                const imageData = ctx.createImageData(this.imageWidth, this.imageHeight)
                imageData.data.set(this.originalImage.data)
                const data = imageData.data


                for (let i = 0; i < data.length; i += 4) {
                    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3
                    data[i] = avg
                    data[i + 1] = avg
                    data[i + 2] = avg
                }

                for (let i = 0; i < data.length; i += 4) {

                    if (data[i] < value) {
                        data[i] = 0
                        data[i + 1] = 0
                        data[i + 2] = 0
                    } else {
                        data[i] = 255
                        data[i + 1] = 255
                        data[i + 2] = 255
                    }
                }

                ctx.putImageData(imageData, 0, 0)
            },
            open() {
                this.inputValue = 128
                this.originalImage = null
                this.displayName = ''
                this.imageFile = null
                this.imageExt = ''
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) => {
                    if (success) {
                        this.add()
                    }
                })
            },
            async add() {
                const thisIns = this
                this.progress = 0
                this.fileUploading = true

                this.formData = new FormData()
                this.formData.append('file', this.imageData)
                this.formData.append('display_name', this.displayName)
                this.formData.append('threshold', this.inputValue)
                this.imageExt = this.imageData.name.substring(this.imageData.name.length - 3, this.imageData.name.length)

                this.$http.post('/api/client/v1/logos/', this.formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress(progressEvent) {
                        thisIns.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                }).then(function(response) {
                    thisIns.$printSuccess(thisIns.$t('print.success.logo'))
                    thisIns.$emit('logoAdded', response.data)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.fileUploading = false
                    thisIns.modalActive = false
                    thisIns.display = ''
                    thisIns.imageFile = null
                    thisIns.imageExt = ''
                })
            }
        },
        computed: {
            getLogoFileType() {
                return `required|image_file:${this.imageExt}`
            },
            validateImageFileType() {
                if (this.imageData) {
                    const fileExtension = this.imageData.name.split('.').pop().toLowerCase()
                    const validExtensions = ['webp', 'jpg', 'jpeg', 'png', 'gif']

                    return validExtensions.includes(fileExtension)
                }
                return false
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dark-layout{
  .custom-file-input:disabled ~ .custom-file-label{
    background: #4b4b4b;
    border-color: #4b4b4b;
  }
}

</style>