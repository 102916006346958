<template>
    <b-modal size="xl" :title="$t('general.preview')" v-model="modalActive" no-close-on-backdrop ok-only>
        <template #default>
            <div class="h-full flex flex-col">
                <b-row class="mx-0 my-2 w-full">
                    <b-col class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 pt-4 p-10 gap-4" v-for="(category) in displayGroups" :key="category.id">
                        <ItemCard
                            :topLabel="category.name"
                            :backgroundColor="category.color"
                            :labelColor="getValidContrast(category.color, category.text_color)"
                            @click.native="selectCategory(category)"
                            :class="{ 'selected-item': selectedCategory && selectedCategory.id === category.id, 'category-theme-colors' : !isValidColor(category.color) || !isValidColor(category.text_color) }"
                        />
                    </b-col>
                </b-row>

                <div class="w-full px-10" v-if="selectedCategory && selectedCategory.products">
                    <div class="border-bottom"></div>
                </div>

                <b-row  class="mx-0 my-2 grow" v-if="selectedCategory && selectedCategory.products" style="overflow-y: auto;">
                    <b-col class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 pb-4 gap-4" v-for="(product, index) in selectedCategory.products" :key="index">
                        <ItemCard
                            :topLabel="product.name"
                            bottomLabelAppend="€"
                            :showPrice="false"
                            :backgroundColor="product.color"
                            :labelColor="getValidContrast(product.color, product.text_color)"
                            :id="'product'+product.id"
                            :class="{ 'item-theme-colors' : !isValidColor(product.color) || !isValidColor(product.text_color) }"
                        />
                    </b-col>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>
<script>
    import {BModal, BRow, BCol} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import ItemCard from '@/views/ProductDisplayGroup/Components/ItemCard.vue'

    export default {
        components: {
            BModal,
            BRow,
            BCol,
            ItemCard
        },
        data() {
            return {
                modalActive: false,
                displayGroups: [],
                businessUnitId: null,
                selectedCategory: {}
            }
        },
        methods: {
            async open(businessUnitId) {
                this.businessUnitId = businessUnitId
                this.selectedCategory = null
                await this.loadData()
                this.modalActive = true
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get(`/api/client/v1/product_display_groups/preview/${this.businessUnitId}`)

                    this.displayGroups = response.data ?? []

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            luminance(r, g, b) {

                if (r === null || r === undefined || g === null || g === undefined || b === null || b === undefined) {
                    return 0
                }

                const RED = 0.2126
                const GREEN = 0.7152
                const BLUE = 0.0722

                const GAMMA = 2.4


                const a = [r, g, b].map((v) => {
                    v /= 255
                    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA)
                })
                return (a[0] * RED) + (a[1] * GREEN) + (a[2] * BLUE)
            },
            hexToRgb(hex) {
                // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
                hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                    return r + r + g + g + b + b
                })

                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
                return result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                } : null
            },
            contrast(rgb1, rgb2) {

                const lum1 = this.luminance(rgb1['r'], rgb1['g'], rgb1['b'])
                const lum2 = this.luminance(rgb2['r'], rgb2['g'], rgb2['b'])

                const brightest = Math.max(lum1, lum2)
                const darkest = Math.min(lum1, lum2)
                return (brightest + 0.05) / (darkest + 0.05)
            },
            getValidContrast(background, textColor) {

                if (!background && !background.trim()) {
                    background = '#666666'
                }

                if (!textColor && !textColor.trim()) {
                    textColor = '#FFFFFF'
                }

                const rgb1 = this.hexToRgb(background)
                const rgb2 = this.hexToRgb(textColor)

                const contrast = this.contrast(rgb1, rgb2)

                if (contrast < 3.5) {
                    const rgbWhite = this.hexToRgb('#ffffff')
                    const contrastWhite = this.contrast(rgbWhite, rgb1)

                    const rgbBlack = this.hexToRgb('#000000')
                    const contrastBlack = this.contrast(rgbBlack, rgb1)

                    if (contrastBlack > contrastWhite) {
                        return '#000000'
                    }

                    return '#FFFFFF'
                }

                return textColor
            },
            isValidColor(strColor) {
                const s = new Option().style
                s.color = strColor
                return s.color !== ''
            },
            selectCategory(category) {
                this.selectedCategory = category
            }
        }
    }
</script>

<style scoped>

.selected-item{
    background: #729092 !important;
}

</style>